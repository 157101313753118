import React from 'react';
import '../spinner.css';

const Spinner = () => (
  <div className="spinner-box">
    <div className="circle-border">
      <div className="circle-core"></div>
    </div>
  </div>
);

export default Spinner;
