import React, { useState } from 'react';
import ShowContent from "./showContent";
import SearchBible from './searchBible';

const BibleIntelligence = () => {
  const [selectedPassage, setSelectedPassage] = useState(null);
  const [progressStart, setProgressStart] = useState(false);

  const handlePassageSelect = (passage) => {
    setSelectedPassage(passage);
  };

  const handleImageLoaded = () => {
    setProgressStart(false);
  };

  return (
    <div>
      <SearchBible
        onPassageSelect={handlePassageSelect}
        onStartProgress={() => setProgressStart(true)} />
      <ShowContent
        selectedPassage={selectedPassage}
        progressStart={progressStart}
        onImageLoaded={handleImageLoaded} />
    </div>
  );
};

export default BibleIntelligence;
