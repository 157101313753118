import { devLog } from "../utils/logging";


export async function getBibleImages(bibleVerse) {
  try {
    // Sending a POST request to the server
    const response = await fetch('https://bol-cloud-run-api-xsqi5dc64a-uc.a.run.app/get-bible-image', {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        body: bibleVerse // Send the bibleVerse as the request body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Assuming the response is text
    const data = await response.text();

    // Log the data for debugging
    devLog('Data:', data);

    // Return the data (which should be the image URL in this case)
    return data;
  } catch (error) {
      console.error('Error fetching image URL:', error);
      return null; // Or handle the error as per your application's logic
  }
}
