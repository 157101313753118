import React, { useState, useEffect } from 'react';
import Spinner from './spinner';

const ShowContent = ({ selectedPassage, progressStart }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [selectedPassage]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="main">
      {selectedPassage && !imageLoaded && <Spinner />}
      <div className="app-container">
        <div className="image-container">
          {selectedPassage && (
            <img
              className="bible-image"
              src={selectedPassage}
              alt='Bible verse'
              onLoad={handleImageLoad}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowContent;
