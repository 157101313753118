import React, { useState } from 'react';
import bibleReference from '../data/bibleReference.json';
import { getBibleImages } from '../services/getBibleImages';
import Spinner from './spinner';
import { devLog } from '../utils/logging';



const BibleSelection = ({ onPassageSelect }) => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const [selectedBook, setSelectedBook] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedVerseStart, setSelectedVerseStart] = useState('');
  const [selectedVerseEnd, setSelectedVerseEnd] = useState('');
  const [availableChapters, setAvailableChapters] = useState([]);
  const [availableVerses, setAvailableVerses] = useState([]);

  const handleBookChange = (event) => {
    const book = event.target.value;
    setSelectedBook(book);
    setSelectedChapter('');
    setSelectedVerseStart('');
    setSelectedVerseEnd('');
    setAvailableChapters([...new Set(bibleReference.filter(ref => ref.book === book).map(ref => ref.chapter))]);
  };

  const handleChapterChange = (event) => {
    const chapter = event.target.value;
    setSelectedChapter(chapter);
    setSelectedVerseStart('');
    setSelectedVerseEnd('');
    setAvailableVerses([...new Set(bibleReference.filter(ref => ref.book === selectedBook && ref.chapter === Number(chapter)).map(ref => ref.verse))]);
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    const userInput = `${selectedBook} ${selectedChapter}:${selectedVerseStart}-${selectedVerseEnd}`;
    devLog(`userInput: ${userInput}`);

    const imageUrl = await getBibleImages(userInput);
    setIsLoading(false); // End loading

    devLog(imageUrl)

    onPassageSelect(imageUrl);

  };

  return (
    <div className="bible-selection">

      <select value={selectedBook} onChange={handleBookChange}>
        <option value="">Select Book</option>
        {[...new Set(bibleReference.map(ref => ref.book))].map(book => (
          <option key={book} value={book}>{book}</option>
        ))}
      </select>

      {selectedBook && (
        <select value={selectedChapter} onChange={handleChapterChange}>
          <option value="">Select Chapter</option>
          {availableChapters.map(chapter => (
            <option key={chapter} value={chapter}>{chapter}</option>
          ))}
        </select>
      )}

      {selectedChapter && (
        <>
          <select value={selectedVerseStart} onChange={e => setSelectedVerseStart(e.target.value)}>
            <option value="">Starting Verse</option>
            {availableVerses.map(verse => (
              <option key={verse} value={verse}>{verse}</option>
            ))}
          </select>

          <select value={selectedVerseEnd} onChange={e => setSelectedVerseEnd(e.target.value)}>
            <option value="">Ending Verse</option>
            {availableVerses.filter(verse => verse >= selectedVerseStart).map(verse => (
              <option key={verse} value={verse}>{verse}</option>
            ))}
          </select>
        </>
      )}

      {selectedVerseEnd && !isLoading && <button onClick={handleSubmit}>Submit</button>}
      {isLoading && <Spinner />}
    </div>
  );
};

export default BibleSelection;
