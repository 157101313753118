import React from 'react';
import BibleIntelligence from "./components/bibleIntelligence";
import './App.css';

function App() {

  return (
    <div className="App">
      <BibleIntelligence />
    </div>
  );
}

export default App;
